import React, {useEffect, useRef, useState} from 'react';
import PostService from "../API/PostService";
import Loader from "../components/UI/loader/Loader";
import MyModal from "../components/UI/MyModal/MyModal";
import {useLocation, useNavigate} from "react-router-dom";
import TestComponent from "../components/TestComponent";
import CreateFormAnswer from "../API/CreateFormAnswer";
import Styles from "../styles/Test.module.css";


const Test = () => {

    const navigate = useNavigate()

    const location = useLocation();

    const [badEntrance, setBadEntrance] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [modalText, setModalText] = useState('')
    const [allImages, setAllImages] = useState()
    const [instructions, setInstructions] = useState()
    const [instructionPointer, setInstructionPointer] = useState(0)
    const [maxLenght, setMaxLenght] = useState(0)
    const [hasStarted, setHasStarted] = useState(false)
    const [hasEnded, setHasEnded] = useState(false)
    const [answers, setAnswers] = useState([...Array(maxLenght)])
    const [textInstruction, setTextInstruction] = useState('')


    const [isNewBlock, setIsNewBlock] = useState(false)
    const time = useRef(Date.now())


    const personalData = {degree:'', age: undefined, gender:'', array_answers: []}


    useEffect(() => {
        if(location.state === null){
            setBadEntrance(true)
        }
        async function fetchData() {
            return await PostService.GetUserTestData(location.state)
        }
        fetchData().then(response => {
            if (response.err === null && response.data.length !== 0) {
                setTextInstruction(response.data.instruction)
                setAllImages(response.data.allImages)
                setInstructions(response.data.testData)
                setMaxLenght(response.data.testData.length)
            } else if (response.err !== null) {
                setModalText(response.err)
                setModal(true)
            }
            setIsLoading(false)
            setIsNewBlock(true)
        });
    }, []);

    const endTest = () =>{
        if(personalData.age === undefined || personalData.degree === '' || personalData.age === ''){
            setModalText('Заполните все параметры перед отправкой')
            setModal(true)
        }
        setIsLoading(true)
        personalData.array_answers = [...answers]
        const data = CreateFormAnswer(personalData, answers, location.state)
        async function sendData() {
            return await PostService.SendAnswers(data)
        }
        sendData().then(response => {
            if (response.err === null) {
                navigate('/')
            } else{
                setModalText(response.err)
                setModal(true)
            }
            setIsLoading(false)
        });

    }


    const handlePersonalDataDegree = (event) =>{
        personalData.degree = event.target.value
    }
    const handlePersonalDataAge = (event) =>{
        personalData.age = event.target.value
    }
    const handlePersonalDataGender = (event) =>{
        personalData.gender = event.target.value
    }


    if(badEntrance){
        return (
            <div className={Styles.BackgroundStart}>
                <h1> На эту страницу можно перейти только через главный экран</h1>
            </div>
        )
    }

    if(isLoading){
        return <Loader/>

    }

    if(!instructions){
        return(
            <div>
                No test
            </div>
        )
    }

    if(!hasStarted){
        return(
            <div className={Styles.BackgroundStart}>
                <div className={Styles.TestStartText}>
                    <h4>{textInstruction}</h4>
                </div>


                <button className={Styles.ButtonStart} onClick={()=>setHasStarted(true)}>Приступить</button>
            </div>
        )
    }

    if(hasEnded){
        return(
            <div className={Styles.BackgroundStart}>

                    <select className={Styles.Field} defaultValue={''}
                            onChange={(e) => handlePersonalDataDegree(e)}>
                        <option disabled value={''}>Образование</option>
                        <option key={"основное общее"} value={"основное общее"}>основное общее</option>
                        <option key={"среднее общее"} value={"среднее общее"}>среднее общее</option>
                        <option key={"среднее профессиональное"} value={"среднее профессиональное"}>среднее
                            профессиональное
                        </option>
                        <option key={"высшее"} value={"высшее"}>высшее</option>
                    </select>
                    <select className={Styles.Field} defaultValue={''}
                            onChange={(e) => handlePersonalDataGender(e)}>
                        <option disabled value={''}>Пол</option>
                        <option key={"мужcкой"} value={"мужской"}>Мужской</option>
                        <option key={"женский"} value={"женский"}>Женский</option>
                    </select>
                    <input className={Styles.Input}
                           placeholder={'Возраст'}
                           type="number"
                           value={personalData.age}
                           onChange={(e) => handlePersonalDataAge(e)}
                           min="1"
                           max="100"
                    />

                    <button className={Styles.ButtonEnd} onClick={endTest}>Завершить</button>

            </div>
        )
    }

    return (
        <div>
            <MyModal visible={modal} setVisible={setModal}>{modalText}</MyModal>
            <TestComponent
                time={time}
                isNewBlock={isNewBlock}
                setIsNewBlock={setIsNewBlock}
                answers={answers}
                setAnswers={setAnswers}
                instructionPointer={instructionPointer}
                instructions={instructions}
                maxLenght={maxLenght}
                setInstructionPointer={setInstructionPointer}
                allImages={allImages}
                setHasEnded={setHasEnded}
            />
        </div>
    );

};


export default Test;
