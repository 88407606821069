import React, {useEffect, useRef, useState} from 'react';
import Styles from "../styles/Test.module.css"

const TestComponent = ({time, isNewBlock, setIsNewBlock, instructionPointer, answers, setAnswers,
                           instructions, setInstructionPointer, maxLenght, allImages, setHasEnded}) => {

    const instructionPointerRef = useRef(instructionPointer);

    const [isWrong, setIsWrong] = useState(false)
    const [isRight, setIsRight] = useState(false)
    const [outOfTime, setOutOfTime] = useState(false)

    useEffect(() => {
        time.current = Date.now()
    }, []);

    useEffect(() => {
        instructionPointerRef.current = instructionPointer;
    }, [instructionPointer]);

    const moveToNextQuestion = () => {
        const tmp = [...answers];
        tmp[instructionPointerRef.current] = {
            block: instructions[instructionPointerRef.current].block,
            time: 0,
        };
        setAnswers(tmp);

        setTimeout(() => {
            setOutOfTime(false);
        }, 298);

        const nextInstructionPointer = instructionPointerRef.current + 1;

        if (nextInstructionPointer < instructions.length && instructions[instructionPointerRef.current].block !== instructions[nextInstructionPointer].block) {
            setIsNewBlock(true);
            setInstructionPointer(prevPointer => prevPointer + 1);
        } else if (nextInstructionPointer < maxLenght) {
            setInstructionPointer(prevPointer => prevPointer + 1);
            time.current = Date.now();
        } else {
            setHasEnded(true);
        }
    };

    useEffect(() => {
        if (!isWrong && !isRight && !isNewBlock) {
            const timer = setTimeout(() => {
                setOutOfTime(true);
                moveToNextQuestion();
            }, 7000);

            return () => clearTimeout(timer);
        }
    }, [isWrong, isRight, isNewBlock, instructionPointer]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            const space = event.key === ' ';
            if(isNewBlock && space){
                setIsNewBlock(false);
                time.current = Date.now();
            }

            if (isWrong || isRight || isNewBlock) return; // Проверяем, нет ли блокировки

            const isLeftKey = (event.key === 'a' || event.key === 'A' || event.key === 'ф' || event.key === 'Ф');
            const isRightKey = (event.key === 'l' || event.key === 'L' || event.key === 'д' || event.key === 'Д');

            if (isLeftKey || isRightKey) {
                const correctSide = instructions[instructionPointerRef.current].right_side === (isLeftKey ? 'left' : 'right');
                correctSide ? setIsRight(true) : setIsWrong(true);
                const tmp = [...answers];
                tmp[instructionPointerRef.current] = {
                    block: instructions[instructionPointerRef.current].block,
                    time: correctSide ? Date.now() - time.current : -1,
                };
                setAnswers(tmp);

                setTimeout(() => {
                    correctSide ? setIsRight(false) : setIsWrong(false);
                }, 298);

                const nextInstructionPointer = instructionPointerRef.current + 1;

                if (nextInstructionPointer < instructions.length && instructions[instructionPointerRef.current].block !== instructions[nextInstructionPointer].block) {
                    setIsNewBlock(true);
                    setInstructionPointer(prevPointer => prevPointer + 1);
                } else if (nextInstructionPointer < maxLenght) {
                    setInstructionPointer(prevPointer => prevPointer + 1);
                    time.current = Date.now();
                } else {
                    setHasEnded(true);
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [maxLenght, isWrong, isRight, isNewBlock]);


    if(outOfTime) {
        return (
            <div className={Styles.BackTest}>
                <h2>Время вышло</h2>
            </div>
        )
    }


    if (isWrong) {
        return (
            <div className={Styles.BackTest}>
                <h2>Ошибка</h2>
            </div>
        )
    }

    if (isRight) {
        return (
            <div className={Styles.BackTest}>
                <h1>Правильно</h1>
            </div>
        )
    }

    if (isNewBlock) {
        return (
            <div className={Styles.BackTest}>

                <div className={Styles.BackLeftRight}>
                    <div className={Styles.Left}>
                        {instructions[instructionPointer].left1}
                    </div>

                    <div className={Styles.Left}>
                        {instructions[instructionPointer].left2}
                    </div>
                </div>

                <h3>Здесь будут предъявляться стимулы, которые нужно отнести к категории.</h3>
                <p>Для продолжения теста нажмите на пробел</p>

                <div className={Styles.BackLeftRight}>
                    <div className={Styles.Right}>
                        {instructions[instructionPointer].right1}
                    </div>

                    <div className={Styles.Right}>
                        {instructions[instructionPointer].right2}
                    </div>
                </div>

            </div>

        )
    }

    return (
        <div className={Styles.BackTest}>
            <div className={Styles.BackLeftRight}>
                <div className={Styles.Left}>
                    {instructions[instructionPointer].left1}
                </div>

                <div className={Styles.Left}>
                {instructions[instructionPointer].left2}
                </div>
            </div>

            <div className={Styles.ImgTest}>
                <img src={`data: ;base64,${allImages[instructions[instructionPointer].attribute_index].image[instructions[instructionPointer].array_index]}`}/>
            </div>

            <div className={Styles.BackLeftRight}>
                <div className={Styles.Right}>
                    {instructions[instructionPointer].right1}
                </div>

                <div className={Styles.Right}>
                    {instructions[instructionPointer].right2}
                </div>
            </div>
        </div>
    );
};

export default TestComponent;