import React, {useEffect, useState} from 'react';
import DynamicTable from "../components/DynamicTable";
import AttributesForm from "../components/AttributesForm";
import MyModal from "../components/UI/MyModal/MyModal";
import CreateFormData from "../API/CreateFormData";
import PostService from "../API/PostService";
import adminCreateStyles from '../styles/TestAndCreation.module.css'
import {useNavigate} from "react-router-dom";
import Loader from "../components/UI/loader/Loader";

const TestCreationPage = () => {

    const navigate = useNavigate()

    const [modalText, setModalText] = useState('')

    const [modalVisible, setModalVisible] = useState(false)

    const [testName, setTestName] = useState({name: ''})

    const [description, setDescription] = useState('')

    const [instruction, setInstruction] = useState('')

    const [isSending, setIsSending] = useState(false)

    const [attributes, setAttributes] = useState([
        { attr: ''},
        { attr: ''},
        { attr: ''},
        { attr: ''}
    ]);

    const [stimulus, setStimulus] = useState([
        {image: [], attr: ''},
        {image: [], attr: ''},
        {image: [], attr: ''},
        {image: [], attr: ''}
    ])


    useEffect(() => {
        const attrs = [...attributes]
        const stimls = [...stimulus]
        attrs.map((attr, index) =>{
            stimls[index].attr = attr.attr
        })
        setStimulus(stimls)
    }, [attributes]);




    const [rows, setRows] = useState([{
        block: 1,
        leftOption1: '',
        leftOption2: '',
        rightOption1: '',
        rightOption2: '',
        stimulusCount: 0
    }]);

    const Submit = async (event) => {
        event.preventDefault()
        const data = CreateFormData(setModalText, setModalVisible,
                                            testName, attributes, stimulus, rows, description, instruction)
        if (data !== false) {
            setIsSending(true)
            const response = await PostService.SendImageTestData(data)
            if(response.err === null){
                setModalText("Тест был сохранен")
                setIsSending(false)
                setModalVisible(true)
                setTimeout(() => {
                    navigate('/')
                }, 2000);
            }else
            {
                setModalText(response.err)
                setIsSending(false)
                setModalVisible(true)
            }
        }

    }

    return (
        isSending? <Loader/> :
        <div className={adminCreateStyles.background}>
            <form onSubmit={Submit}>
                <MyModal visible={modalVisible} setVisible={setModalVisible}>
                    {modalText}
                </MyModal>
                <AttributesForm
                    attributes={attributes}
                    stimulus={stimulus}
                    setAttributes={setAttributes}
                    setStimulus={setStimulus}
                    setDescription={setDescription}
                    setTestName={setTestName}
                    setInstruction={setInstruction}
                />
                <DynamicTable rows={rows} setRows={setRows} attributes={attributes} stimulus={stimulus}/>
                <button className={adminCreateStyles.buttonChoose} type="submit">Create Test</button>
            </form>
        </div>
    )
};

export default TestCreationPage;
