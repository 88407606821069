import React, {useState} from 'react';
import attributesFormStyles from '../styles/TestAndCreation.module.css'
import DescriptionInput from "./UI/DescriptionInput/DescriptionInput";
import PostService from "../API/PostService";

const AttributesForm = ({attributes, setAttributes, stimulus, setStimulus, setTestName, setDescription, setInstruction}) => {

    const [descriptionVisibility, setDescriptionVisibility] = useState(false)

    const [instructionVisibility, setInstructionVisibility] = useState(false)

    const handleTextChange = (index, event) => {
        const newItems = [...attributes];
        newItems[index].attr = event.target.value;
        setAttributes(newItems);
    };

    const handleImageChange = (index, event) => {
        const newItems = [...stimulus];
        newItems[index].image = [...newItems[index].image, ...Array.from(event.target.files)];
        setStimulus(newItems);
    };

    const deleteImages = (index) =>{
        const newItems = [...stimulus];
        newItems[index].image = []
        setStimulus(newItems);
    }

    const handleDecoyButton = (e, index) =>{
        e.preventDefault()
        document.getElementById(`file-input${index}`).click()
    }

    const descriptionAppear = (e) =>{
        e.preventDefault()
        setDescriptionVisibility(true)
    }

    const instructionAppear = (e) =>{
        e.preventDefault()
        setInstructionVisibility(true)
    }


    return (
        <div className={attributesFormStyles.CreateTestBack}>
            <th>Test's name</th>
            <input
                className={attributesFormStyles.InputName}
                type="text"
                placeholder={'name'}

                onChange={(e) => setTestName({name: e.target.value})}
            />
            <button className={attributesFormStyles.ButtonDescription}
                    onClick={(e) => descriptionAppear(e)}
            >
                Добавить описание
            </button>
            <button className={attributesFormStyles.ButtonDescription}
                    onClick={(e) => instructionAppear(e)}
            >
                Добавить инструкцию
            </button>
            <th>Attributes</th>
            {attributes.map((attribute, index) => (
                <div className={attributesFormStyles.AttributeBack} key={`attribute-${index}`}>
                    <input
                        className={attributesFormStyles.InputAttribute}
                        type="text"
                        placeholder={`Attribute ${index + 1}`}
                        value={attribute.attr}
                        onChange={(e) => handleTextChange(index, e)}
                    />
                    <input
                        id={`file-input${index}`}
                        style={{display: "none"}}
                        type="file"
                        multiple
                        onChange={(e) => handleImageChange(index, e)}
                    />
                    <button
                        onClick={(e) => handleDecoyButton(e, index)}
                        className={attributesFormStyles.buttonChoose}
                    >
                        Выбрать изображения
                    </button>
                    <text>
                        {stimulus[index].image.length > 0 ?
                            `Количество файлов:${stimulus[index].image.length}`
                            :
                            "Файлы не прекреплены"}
                    </text>
                    <button
                        className={attributesFormStyles.buttonClean}
                        onClick={() => deleteImages(index)}
                    >
                        Удалить
                    </button>
                </div>
            ))}
            <DescriptionInput
                visible={descriptionVisibility}
                setVisible={setDescriptionVisibility}
                setInputText={setDescription}/>
            <DescriptionInput
                visible={instructionVisibility}
                setVisible={setInstructionVisibility}
                setInputText={setInstruction}
            />
        </div>
    );
};

export default AttributesForm;