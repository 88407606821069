import React, { useState, useEffect } from 'react';
import tableRowStyles from '../styles/TestAndCreation.module.css'

const TableRow = ({ rowIndex, rowData, handleRowChange, attributes, stimulus}) => {
    const blockOptions = [1, 2, 3, 4, 5, 6, 7];
    const typeOptions = ['practice', 'test'];
    const [optionAdvanced, setOptionsAdvanced] = useState(false);

    const handleInputChange = (field, value) => {
        handleRowChange(rowIndex, { ...rowData, [field]: value });
    };

    const handleLeftOptionsChange1 = (option) => {
        handleInputChange('leftOption1', option);
    };

    const handleLeftOptionsChange2 = (option) => {
        handleInputChange('leftOption2', option)
    }

    const handleRightOptionsChange1 = (option) => {
        handleInputChange('rightOption1', option);
    };

    const handleRightOptionsChange2 = (option) => {
        handleInputChange('rightOption2', option);
    };

    const createNewSelect= () =>{
        setOptionsAdvanced(true);
    }

    const getMinStimulusCount = () =>{
        const filtered = stimulus.filter(option => (
            option.attr!=='' && (
                rowData.leftOption1===option.attr ||
                rowData.leftOption2===option.attr ||
                rowData.rightOption1===option.attr ||
                rowData.rightOption1===option.attr
                )
            )
        )
        if (filtered.length === 0) {
            return 0; // или любое другое значение по умолчанию
        }

        return Math.min(...filtered.map(option => option.image.length));
    }


    return (
        <tr>
            <td>
                {rowIndex+1}
            </td>
            <td>
                <select defaultValue={''} onChange={(e) => handleLeftOptionsChange1(e.target.value)}>
                    <option disabled value={''}>left option</option>
                    {
                        attributes.filter(option => !(rowData.rightOption1===option.attr || rowData.rightOption2===option.attr || rowData.leftOption2===option.attr)).map(option => (
                            <option key={option.attr} value={option.attr}>{option.attr}</option>
                        ))}
                </select>
                {
                optionAdvanced ?
                <select defaultValue={''} onChange={(e) => handleLeftOptionsChange2(e.target.value)}>
                    <option disabled value={''}>left option</option>
                    {
                        attributes.filter(option => !(rowData.rightOption1===option.attr || rowData.rightOption2===option.attr || rowData.leftOption1===option.attr)).map(option => (
                            <option key={option.attr} value={option.attr}>{option.attr}</option>
                        ))}
                </select>
                :
                <button className={tableRowStyles.buttonPlus} onClick={createNewSelect}>+</button>
                }
            </td>
            <td>
                <select defaultValue={''} onChange={(e) => handleRightOptionsChange1(e.target.value)}>
                    <option disabled value={''}>right option</option>
                    {
                        attributes.filter(option => !(rowData.leftOption1===option.attr || rowData.leftOption2===option.attr || rowData.rightOption2===option.attr)).map(option => (
                            <option key={option.attr} value={option.attr}>{option.attr}</option>
                        ))}
                </select>
                {
                    optionAdvanced ?
                        <select defaultValue={''} onChange={(e) => handleRightOptionsChange2(e.target.value)}>
                            <option disabled value={''}>right option</option>
                            {
                                attributes.filter(option => !(rowData.leftOption1===option.attr || rowData.leftOption2===option.attr || rowData.rightOption1===option.attr)).map(option => (
                                    <option key={option.attr} value={option.attr}>{option.attr}</option>
                                ))}
                        </select>
                        :
                        <button className={tableRowStyles.buttonPlus} onClick={createNewSelect}>+</button>
                }
            </td>
            <td>
                <input
                    className={tableRowStyles.InputAttribute}
                    type="number"
                    value={rowData.stimulusCount}
                    onChange={(e) => handleInputChange('stimulusCount', e.target.value)}
                    min="1"
                    max={getMinStimulusCount()}
                />
            </td>
        </tr>
    );
};

export default TableRow